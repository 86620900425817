import React from "react";
import * as styles from "./BlogList.module.css";

export default function BlogList() {
    return (
        <div className={styles.blogListWrapper}>
            <div className={styles.blogListTitle}>It's quiet in here...</div>
            <div>No but seriously, please check back later for more upcoming contents :)</div>
        </div>
    )
}