import * as React from "react";
import Meta from "../components/shared/Meta";
import Layout from "../components/shared/Layout";
import BlogList from "../components/bloglist/BlogList";

const Blog = () => {
    return (
        <Layout>
            <Meta title="Blog"/>
            <BlogList/>
        </Layout>
    )
}

export default Blog;